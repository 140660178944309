<template>
	<span>{{short}}</span>
</template>

<script>

export default {
    name: "CSShortText",
    props: {
        data: {
        	type:String,
        	default:''
        },
        max: {
        	type:Number,
        	default:50
        },
    },
    computed: {
    	short(){
	    	let shortenedText = this.data.substring(0, this.max) + (this.data.length > this.max ? "..." : "");
            shortenedText = shortenedText.replace(/(<([^<>]+)>)/gm, "");
			return shortenedText
    	}
    }
};
</script>

<style lang="scss" scoped>

</style>
